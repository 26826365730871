// Environment Configuration object to store details of server urls
// for dev server use : dev
// for test server use : test
// for production server use : pre

const env = 'test';

const LogisticApiUrl = `https://${env}-api.vooyapp.com/api/`;
// const OtpApiUrl = `http://localhost:6020/api/v1/`;
const OtpApiUrl = `https://${env}-otp.vooyapp.com/api/v1/`;
const SupportApiUrl = `https://${env}-support.vooyapp.com/api/v1/`;
// const TokenApiUrl = `http://localhost:6007/`;
const TokenApiUrl = `https://${env}-token.vooyapp.com/`;
const MasterApiUrl = `https://${env}-master.vooyapp.com/api/v1/`;
const LocalAPiUrl = `https://test-payment.vooyapp.com/api/v1/`

//test

const Environment = {

  getUrl() {
    return LogisticApiUrl;
  },
  getOtpAPIUrl() {
    return OtpApiUrl;
  },
  getSupportAPIUrl() {
    return SupportApiUrl;
  },
  getTokenAPIUrl() {
    return TokenApiUrl;
  },
  getVooyMasterServiceUrl() {
    return MasterApiUrl;
  },
  getVooyLocalAPiUrl() {
    return LocalAPiUrl;
  },
};

export { Environment };

